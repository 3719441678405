import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import "./footer.css";
import Heading from "../../text/heading";
import Wrapper from "../../components2/wrapper/wrapper";
import MediumText from "../../text/mediumText";
import Para from "../../text/para";
import { NavLink } from "react-router-dom";
import saudi from "../../assets/saudi.png";

import qatar from "../../assets/qatar2.png";
import FooterBottom from "./footerbottom";
import { Link } from "react-scroll";
import { IoLogoWhatsapp } from "react-icons/io";

import { BsFillArrowUpSquareFill } from "react-icons/bs";
import { AiOutlineArrowUp } from "react-icons/ai";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaFacebookMessenger,
} from "react-icons/fa";

// component for 4 countries
const Country = (props) => {

  return (
    <div className="col-md-3 d-flex justify-content-center align-items-center">
      <div>
        <img src={props.img} className="mx-auto d-block" />

        <center>
          {" "}
          <Para weight="normal" size="m" color="green">
            {props.name}
          </Para>
          <Para weight="light" size="s" color="white">
            {props.street}
            <br />
            <b> Whatsapp:</b>
            <a href={"https://wa.me/" + props.number + "?text=Hello "} >
              {props.number}
            </a>
            <br />
          </Para>
        </center>
      </div>
    </div>

  );

};

export default Country;