import React from "react";
import "./iframe.css";
import Wrapper from "../../components2/wrapper/wrapper";

const Iframe = (props) => {
  return (
    <Wrapper className="map container" padding="yes">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d379.24484392020963!2d55.36281954180184!3d25.27040601522647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5db080700677%3A0xb6e3d61557d02181!2sPrometric%20DHA%20guidance%20Center!5e0!3m2!1sen!2sin!4v1686811076728!5m2!1sen!2sin"
        width="100%"
        height="300px"
        styles={"border:0"}
        allowFullScreen=""
      ></iframe>
    </Wrapper>
  );
};

export default Iframe;
