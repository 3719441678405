import React from "react";
import "./card.css";
import Img from "../../assets/cardImg.jpg";
import Wrapper from "../../components2/wrapper/wrapper";
import Heading from "../../text/heading";
import law from "../../assets/heroImg-3.jpg"

import { IoIosArrowDroprightCircle } from "react-icons/io";
import Para from "../../text/para";

// images
import doctor from "../../assets/doctor.jpg"

import doctor1 from "../../assets/doctorImg1.jpg";
import doctor2 from "../../assets/doctorImg2.jpg";
import doctor3 from "../../assets/doctorImgn3.jpg";
import doctor4 from "../../assets/doctorImg4.jpg";

import career from "../../assets/careerImg1.jpg";

import tuitions from "../../assets/tuition.jpg";
import apartment from "../../assets/apartment.jpg";

const PrometricGuidance = [
  {
    id: 1,
    name: "PROMETRIC GUIDANCE INDIA",
    img: doctor1,
    // title: "MOH Gudance Hyderabad",
    title: "DATA FLOW guidance, PSV Report Guidance, India",
    para: `We assist all medical professionals by providing
    DHA, MOH, DOH, QCHP, SLE  data flow and MCQ material for prometric exam.`,
    link: "https://www.mohhyd.com/",
    number: "917097615132",
  },

  {
    id: 2,
    name: "PROMETRIC GUIDANCE UAE",
    img: doctor2,
    title: "DHA Dubai/DOH Abu Dhabi/MOH sharjah Guidance Center for medical professionals to practice in UAE.",
    para: `We guide you in complete process for MOH, HAAD, DHA , data flow and MCQ material for all medical fields to clear prometric exam U.A.E`,
    link: "https://www.dhauae.com/",
    number: "917097615132"
  },

  {
    id: 3,
    name: "PROMETRIC GUIDANCE SAUDI ARABIA (SLE)",
    img: doctor3,
    title: "SLE (Saudi Licensing Exam)",
    para: `We assist in SLE process (mummaris plus, PSV(Data Flow) report, Exam) One stop solution for MCQ notes, DHA`,
    link: "https://www.mohsaudi.com/",
    number:"966546200130"
  },

  {
    id: 4,
    name: "PROMETRIC GUIDANCE Qatar QCHP exam",
    img: doctor4,
    title: "Data Flow",
    para: `We assist all medical professionals in QCHP, MOPH exam , PSV report, MCQ notes, and licencing for qatar prometric,`,
    link: "https://www.mohhyd.com/",
    number: "97477196015",
  },
];

const ZimsCareerGuidance = [
  {
    id: 1,
    name: "Career Advise",
    img: career,
    title: "Zims Career Advisor for Career options for after 10th after inter , after degree",
    para: `It is difficult for students to choose the right career path after 10th, inter. zims provideCareer guidance basing on the skill set of students`,
    link: "http://zimscareeradvisors.com/",
    number: "917396484655",
  },
  {
    id: 2,
    name: "Legal Advisor",
    img: law,
    title: "LSF (law student federation)",
    para: `A one stop solution provider for all law students /legal  professionals to seek carreer oppurtunities in india and abroad
    we assist in all types of Notes for LLB 3 years , LLB 5 years, llM exam's `,
    link: "https://lawstudentsfederation.co.in/",
    number: "917396484655",
  },
];

const ZimsHomeTuitions = [
  {
    id: 1,
    name: "Zims Home Tuitions",
    img: tuitions,
    title: "Zims Home Tuitions",
    para: `ZIMS provides a onestop solution for age group 10-18 years by mentoring all academic subjects. We are specialised with 
    a. Slow learners,
   b. Neglected Child,
   c. Physically challenged and 
   d. Forign language tutions`,
    link: "http://zimstutions.com/",
    number:"917196484655"
  },
  {
    id: 2,
    name: "Medical tutions ",
    img: doctor,
    title: "We provide tuitions for anotomy physiology biochemistry, gyno--- for MBBS , BDS. BPT, Pharma, Nursing.",
    para: `we Provide 1-1 mentors , class room teaching for referred students`,
    link: "http://zimstutions.com/",
    number:"917396484655",
  },
];

const ZimsRentals = [
  {
    id: 1,
    name: "Zims Rentals",
    img: Img,
    title: "Co Working Space",
    para: `we provide coworking space, virtual office, confrence halls, offices for video shoots.
    available at a.hyderabad india 
    b. dubai UAE
    c. Doha Qatar`,
    //  links
    link: "http://www.startupbusinesscenter.in",
    number:"971544439816",
  },
  {
    id: 2,
    name: "Zims Furnished Apartments",
    img: apartment,
    title: "Furnished Apartments",
    para: `Zims offers variety of housing for shortterm long term and daycare stay.
    services offered in 
    a.hyderabd india
    b.dubai, sharjah , ajman . UAE
    c.Doha Qatar`,
    //  links
    link: "zimscareeradvisors.com",
    number:"971544439816"
  },
];

const startupoffice = [
  {
    id: 1,
    name: "Start Up Dubai",
    img: Img,
    title: "Start Up in dubai ",
    para: `we fecilitate company foramtion , complete setup for LLC, cellphone company , project management company for startups in Dubai UAE.`,

    link: "https://dubaistartup.in/",
    number: "971544439816",
  },
  {
    id: 2,
    name: "Start up Qatar ",
    img: apartment,
    title: "Start Up in dubai and overseas",
    para: `we fecilitate company foramtion , complete setup for LLC, cellphone company , project management company for startups in Doha Qatar.`,

    link: "https://dubaistartup.in/",
    number: "97477196015 ",
  },
];

const Whatsappbtn = (props) => {
  return (

<a href= {"https://wa.me/"+props.number } className=" float-end" > <button type="button" class="btn btn-success">Click Here to chat with expert</button></a> 

  );
};

const Card = () => {
  const prometricMap = PrometricGuidance.map((el, i) => {
    return (
      <div className="col-md-6 col-sm-12 card-col" key={el.id}>
        <div className="card">
          <img src={el.img} />
          <div className="intro">
            <div className="container">
              <Para color="green" size="m" className="heading paraM-text">
                {el.name}
              </Para>

              <hr className="line" />

              <Para color="white" size="s" weight="light">
                {el.title}
              </Para>

              <div className="hide-para">
                <Para color="white" size="s" weight="light">
                  {el.para}
                </Para>

                <a href={el.link} className="card-btn section-btn">
                  Learn More
                  <span>
                    <IoIosArrowDroprightCircle />
                  </span> 
                  <Whatsappbtn number={el.number}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const ZimsCareerMap = ZimsCareerGuidance.map((el, j) => {
    return (
      <div className="col-md-6 col-sm-12 card-col" key={el.id}>
        <div className="card">
          <img src={el.img} />
          <div className="intro">
            <div className="container">
              <Para color="green" size="m" className="heading paraM-text">
                {el.name}
              </Para>

              <hr className="line" />

              <Para color="white" size="l" weight="normal">
                {el.title}
              </Para>

              <div className="hide-para">
                <Para color="white" size="s" weight="light">
                  {el.para}
                </Para>

                <a href={el.link} className="card-btn section-btn">
                  Learn More
                  <span>
                    <IoIosArrowDroprightCircle />
                  </span>
                  <Whatsappbtn number={el.number}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const ZimsTuitionsMap = ZimsHomeTuitions.map((el, i) => {
    return (
      <div className="col-md-6 col-sm-12 card-col" key={el.id}>
        <div className="card">
          <img src={el.img} />
          <div className="intro">
            <div className="container">
              <Para color="green" size="m" className="heading paraM-text">
                {el.name}
              </Para>

              <hr className="line" />

              <Para color="white" size="l" weight="normal">
                {el.title}
              </Para>

              <div className="hide-para">
                <Para color="white" size="s" weight="light">
                  {el.para}
                </Para>

                <a href={el.link} className="card-btn section-btn">
                  Learn More
                  <span>
                    <IoIosArrowDroprightCircle />
                  </span>
                  <Whatsappbtn number={el.number}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const ZimsRentalsMap = ZimsRentals.map((el, k) => {
    return (
      <div className="col-md-6 col-sm-12 card-col" key={el.id}>
        <div className="card">
          <img src={el.img} />
          <div className="intro">
            <div className="container">
              <Para color="green" size="m" className="heading paraM-text">
                {el.name}
              </Para>

              <hr className="line" />

              <Para color="white" size="l" weight="normal">
                {el.title}
              </Para>

              <div className="hide-para">
                <Para color="white" size="s" weight="light">
                  {el.para}
                </Para>

                <a href={el.link} className="card-btn section-btn">
                  Learn More
                  <span>
                    <IoIosArrowDroprightCircle />
                  </span>
                  <Whatsappbtn number={el.number}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const zimsStartupMap = startupoffice.map((el, l) => {
    return (
      <div className="col-md-6 col-sm-12 card-col" key={l}>
        <div className="card">
          <img src={el.img} />
          <div className="intro">
            <div className="container">
              <Para color="green" size="m" className="heading paraM-text">
                {el.name}
              </Para>

              <hr className="line" />

              <Para color="white" size="l" weight="normal">
                {el.title}
              </Para>

              <div className="hide-para">
                <Para color="white" size="s" weight="light">
                  {el.para}
                </Para>

                <a href={el.link} className="card-btn section-btn">
                  Learn More
                  <span>
                    <IoIosArrowDroprightCircle />
                  </span>
                  <Whatsappbtn number={el.number}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  // const mapArr = obj.map((el, i) => {
  //   return (
  //     <>
  //       <div className="col-md-6 col-sm-12 card-col" key={i}>
  //         <div className="card">
  //           <img src={el.img} />
  //           <div className="intro">
  //             <div className="container">
  //               <Para color="green" size="m" className="heading paraM-text">
  //                 {el.name}
  //               </Para>

  //               <hr className="line" />

  //               <Para color="white" size="l" weight="normal">
  //                 {el.title}
  //               </Para>

  //               <div className="hide-para">
  //                 <Para color="white" size="s" weight="light">
  //                   {el.para}
  //                   {/* sum is simply dummy text of the printing and typesetting
  //                 industry. Lorem Ipsum has been the industry's "break"
  //                 <span className="">
  //                   {" "}
  //                   sum is simply dummy text of the printing and typesetting
  //                   industry.
  //                 </span> */}
  //                 </Para>

  //                 <NavLink className="card-btn section-btn" to="services">
  //                   Learn More
  //                   <span>
  //                     <IoIosArrowDroprightCircle />
  //                   </span>
  //                 </NavLink>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // });
  return (
    <div id="services-scroll">
      <Wrapper
        className="myCards container myContainer"
        color="white"
        paddingTop="3"
        paddingBottom="5"
      >
        {/* <span className="big-text">Our Global Presence</span> */}
        <Heading color="blue" size="s" className="text-center">
          Zims Group of Companies
        </Heading>

        <Para color="green" size="m" weight="normal" className="text-center">
          We are all set up to help you in a journey of Education, Career and
          Businesses.
        </Para>

        {/* <br /> */}
        {/* <span className="mt-1 para-text">Lorem Ipsum dolor sit amet</span> */}
        {/* <div className="row pt-4">{mapArr}</div> */}

        <div className="nav-tabs-cards">
          {/* --------------------------- Navbar ------------------- */}
          <ul className="nav nav-tabs mt-5" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Prometric Guidance
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Career Advise

              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Mentoring
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="other-tab"
                data-bs-toggle="tab"
                data-bs-target="#other"
                type="button"
                role="tab"
                aria-controls="other"
                aria-selected="false"
              >
                Rentals
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="other2-tab"
                data-bs-toggle="tab"
                data-bs-target="#other2"
                type="button"
                role="tab"
                aria-controls="other2"
                aria-selected="false"
              >
                Start-Up
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="row mt-5">{prometricMap}</div>
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div className="row mt-5">{ZimsCareerMap}</div>
            </div>
            <div
              className="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div className="row mt-5">{ZimsTuitionsMap}</div>
            </div>

            <div
              className="tab-pane fade"
              id="other"
              role="tabpanel"
              aria-labelledby="other-tab"
            >
              <div className="row mt-5">{ZimsRentalsMap}</div>
            </div>
            <div
              className="tab-pane fade"
              id="other2"
              role="tabpanel"
              aria-labelledby="other2-tab"
            >
              <div className="row mt-5">{zimsStartupMap}</div>
            </div>
          </div>
        </div>
        {/* --------------------------- Navbar -------------------------- */}
      </Wrapper>
    </div>
  );
};

// const Card = (props) => {
//   const mapArr = obj.map((el, i) => {
//     return (
//       <>
//         {/* navbar */}
//         {/* --------------------------- Navbar ------------------- */}
//         <ul className="nav nav-tabs" id="myTab" role="tablist">
//           <li className="nav-item" role="presentation">
//             <button
//               className="nav-link active"
//               id="home-tab"
//               data-bs-toggle="tab"
//               data-bs-target="#home"
//               type="button"
//               role="tab"
//               aria-controls="home"
//               aria-selected="true"
//             >

//             </button>
//           </li>
//           <li className="nav-item" role="presentation">
//             <button
//               className="nav-link"
//               id="profile-tab"
//               data-bs-toggle="tab"
//               data-bs-target="#profile"
//               type="button"
//               role="tab"
//               aria-controls="profile"
//               aria-selected="false"
//             >
//               Profile
//             </button>
//           </li>
//           <li className="nav-item" role="presentation">
//             <button
//               className="nav-link"
//               id="contact-tab"
//               data-bs-toggle="tab"
//               data-bs-target="#contact"
//               type="button"
//               role="tab"
//               aria-controls="contact"
//               aria-selected="false"
//             >
//               Contact
//             </button>
//           </li>
//         </ul>
//         <div className="tab-content" id="myTabContent">
//           <div
//             className="tab-pane fade show active"
//             id="home"
//             role="tabpanel"
//             aria-labelledby="home-tab"
//           >
//             Home
//           </div>
//           <div
//             className="tab-pane fade"
//             id="profile"
//             role="tabpanel"
//             aria-labelledby="profile-tab"
//           >
//             About
//           </div>
//           <div
//             className="tab-pane fade"
//             id="contact"
//             role="tabpanel"
//             aria-labelledby="contact-tab"
//           >
//             Contact
//           </div>
//         </div>
//         {/* --------------------------- Navbar -------------------------- */}
//         {/* navbar */}
//         <div className="col-md-6 col-sm-12 card-col" key={i}>
//           <div className="card">
//             <img src={el.img} />
//             <div className="intro">
//               <div className="container">
//                 <Para color="green" size="m" className="heading paraM-text">
//                   {el.name}
//                 </Para>

//                 <hr className="line" />

//                 <Para color="white" size="l" weight="normal">
//                   {el.title}
//                 </Para>

//                 <div className="hide-para">
//                   <Para color="white" size="s" weight="light">
//                     {el.para}
//                     {/* sum is simply dummy text of the printing and typesetting
//                   industry. Lorem Ipsum has been the industry's "break"
//                   <span className="">
//                     {" "}
//                     sum is simply dummy text of the printing and typesetting
//                     industry.
//                   </span> */}
//                   </Para>

//                   <NavLink className="card-btn section-btn" to="services">
//                     Learn More
//                     <span>
//                       <IoIosArrowDroprightCircle />
//                     </span>
//                   </NavLink>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   });
// };

export default Card;
